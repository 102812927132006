import { Alert, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { AsyncTaskStatus, AttributeTable, AttributeType } from "api-shared";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseDialog from "../../../components/dialogues/BaseDialog";
import DialogBackButton from "../../../components/dialogues/DialogBackButton";
import DialogButton from "../../../components/dialogues/DialogButton";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients.ts";
import { useSuperAdminCustomFields } from "../../../domain/superadmin/custom-fields.ts";
import {
    useSuperAdminMigratedCustomFieldMutation,
    useSuperAdminMigratedCustomFieldTaskStatusQuery,
} from "../../../domain/superadmin/migrated-custom-field.ts";

const CONFIRMATION_TEXT = "migrate permanently";

interface MigrateComboboxIntoTreeDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

export const MigrateComboboxIntoTreeDialog = ({ open, onClose, onSubmit }: MigrateComboboxIntoTreeDialogProps) => {
    const { t } = useTranslation();
    const [confirmationText, setConfirmationText] = useState("");
    const [client, setClient] = useState<number | null | undefined>();
    const [field, setField] = useState<number | null | undefined>();

    const [taskId, setTaskId] = useState<number>();

    const superAdminMigratedCustomFieldMutation = useSuperAdminMigratedCustomFieldMutation({
        onSuccess: ({ taskId }) => {
            setTaskId(taskId);
        },
    });

    const handleOnClose = useCallback(() => {
        onClose();
        setClient(undefined);
        setField(undefined);
        setTaskId(undefined);
        setConfirmationText("");
    }, [onClose]);

    const onSuccess = useCallback(() => {
        setTaskId(undefined);
        handleOnClose();
        onSubmit();
    }, [handleOnClose, onSubmit]);

    const taskQuery = useSuperAdminMigratedCustomFieldTaskStatusQuery({ id: taskId, onFinish: onSuccess });

    const clients = useSuperadminCustomers();
    const clientOptions: Option<number | undefined | null>[] = [
        { value: undefined, label: "Please select..." },
        ...(clients?.data?.map(({ id, name }: { id?: number | null; name: string }) => ({
            value: id,
            label: name,
        })) ?? []),
    ];

    const fields = useSuperAdminCustomFields(client, client !== undefined);
    const fieldOptions: Option<number | undefined>[] = [
        { value: undefined, label: "Please select..." },
        ...(fields?.data
            ?.filter(
                ({ type, tableName, clientId }) =>
                    type === AttributeType.Combobox && tableName === AttributeTable.CustomValues && clientId != null,
            )
            ?.map(({ id, title }) => ({ value: id, label: title })) ?? []),
    ];

    const canSubmit =
        confirmationText === CONFIRMATION_TEXT && !superAdminMigratedCustomFieldMutation.isLoading && taskQuery.data == undefined;

    const handleOnSubmit = () => {
        if (!canSubmit || client == null || field == null) {
            return;
        }

        superAdminMigratedCustomFieldMutation.mutate({ clientId: client, attributeId: field });
    };

    const handleClientChange = (option: Option<number | null | undefined> | null) => {
        if (option !== null) {
            setClient(option.value);
        }
        setField(undefined);
        setConfirmationText("");
    };

    const handleFieldChange = (option: Option<number | null | undefined> | null) => {
        if (option !== null) {
            setField(option.value);
        }
        setConfirmationText("");
    };

    const actions = [
        <DialogBackButton key="back" translate={t} onClose={handleOnClose} />,
        <DialogButton key="migrate" name="Migrate" variant="contained" color="error" onClick={handleOnSubmit} disabled={!canSubmit} />,
    ];

    return (
        <BaseDialog
            actions={actions}
            open={open}
            onClose={handleOnClose}
            onSubmit={handleOnSubmit}
            title="Migrate Combobox Field into a Tree"
        >
            <Alert severity="error">
                This migration is permanent and cannot be undone, not even via the helpdesk. To confirm the migration, please enter the text
                &quot;{CONFIRMATION_TEXT}&quot; in the field below. <br />
                <p>
                    Depending on the amount of selectable options in the attribute and the size of the customer platform, the migration may
                    take several minutes. It is recommended to execute the migration during periods of low customer activity, as any changes
                    related to the migrated field might not be migrated correctly.
                </p>
            </Alert>
            <Select
                label="Client"
                options={clientOptions}
                value={clientOptions.find(({ value }) => value === client)}
                onChange={handleClientChange}
                isLoading={!clients.isSuccess}
                menuPortalTarget={document.body}
                isSearchable
            />
            <Select
                label="Combobox Field"
                options={fieldOptions}
                value={fieldOptions.find(({ value }) => value === field)}
                onChange={handleFieldChange}
                isLoading={!fields.isSuccess}
                isDisabled={client === undefined}
                menuPortalTarget={document.body}
                isSearchable
            />
            <TextField
                value={confirmationText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmationText(event.target.value)}
                margin="normal"
                placeholder={CONFIRMATION_TEXT}
                label="Confirmation"
                fullWidth
                disabled={client === undefined}
            />
            {taskQuery.isSuccess && taskQuery.data.status === AsyncTaskStatus.InProgress ? (
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="body1">Migration in progress. This may take a few minutes...</Typography>
                    <CircularProgress value={taskQuery.data.progress * 100} variant="determinate" />
                </Stack>
            ) : null}
        </BaseDialog>
    );
};
