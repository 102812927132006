import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    BasicMessageDto,
    CustomFieldsOrderType,
    SuperAdminAttributeDto,
    SuperAdminAttributeListDto,
    SuperAdminCustomValueDto,
    SuperAdminCustomValueListDto,
} from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const SUPERADMIN_CUSTOM_FIELDS_PATH = "superadmin/custom-fields";

interface ChangeCustomFieldsOrderInputDto {
    activeClientId: number;
    orderType: CustomFieldsOrderType | null;
    customFields: SuperAdminAttributeDto[];
}

export const SuperAdminCustomFieldQueryKeys = {
    all: [SUPERADMIN_CUSTOM_FIELDS_PATH] as const,
    forClient: (clientId: number | null) => [...SuperAdminCustomFieldQueryKeys.all, clientId] as const,
};

export const useSuperAdminCustomFields = (clientId?: number | null, enabled?: boolean) => {
    return useQuery({
        queryKey: clientId !== undefined ? SuperAdminCustomFieldQueryKeys.forClient(clientId) : SuperAdminCustomFieldQueryKeys.all,
        queryFn: ({ queryKey, signal }) => {
            const id = queryKey[1] !== undefined ? `?clientId=${queryKey[1]}` : "";
            return apiGet<SuperAdminAttributeListDto>(`${queryKey[0]}${id}`, { signal });
        },
        enabled,
    });
};

export const useSuperAdminCreateCustomField = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: SuperAdminAttributeDto) => apiPost<SuperAdminAttributeDto>(SUPERADMIN_CUSTOM_FIELDS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CUSTOM_FIELDS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CUSTOMFIELD_CREATED));
        },
    });
};

export const useSuperAdminUpdateCustomField = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, field }: { id: number; field: SuperAdminAttributeDto }) =>
            apiPatch<SuperAdminAttributeDto>(`${SUPERADMIN_CUSTOM_FIELDS_PATH}/${id}`, field),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CUSTOM_FIELDS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CUSTOMFIELD_UPDATED));
        },
    });
};

export const useSuperAdminDeleteCustomField = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (customFieldId: number) => apiDelete<BasicMessageDto>(`${SUPERADMIN_CUSTOM_FIELDS_PATH}/${customFieldId}`),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CUSTOM_FIELDS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CUSTOMFIELD_DELETED));
        },
    });
};

export const useSuperAdminUpdateCustomFieldsOrder = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: ChangeCustomFieldsOrderInputDto) =>
            apiPatch<SuperAdminAttributeListDto>(`${SUPERADMIN_CUSTOM_FIELDS_PATH}/change-order/${data.activeClientId}`, {
                customFields: data.customFields,
                orderType: data.orderType,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CUSTOM_FIELDS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CUSTOMFIELD_UPDATED));
        },
    });
};

const SUPERADMIN_CUSTOM_VALUES_PATH = "superadmin/custom-values";

const CustomValueQueryKeys = {
    all: [SUPERADMIN_CUSTOM_VALUES_PATH] as const,
    forClient: (clientId: number | null) => [...CustomValueQueryKeys.all, clientId] as const,
};

interface ChangeCustomValuesOrderInputDto {
    measureAttributeId: number;
    customValues: SuperAdminCustomValueDto[];
}

export const useSuperAdminCustomValues = (clientId?: number | null, enabled?: boolean) => {
    return useQuery({
        queryKey: clientId === undefined ? CustomValueQueryKeys.all : CustomValueQueryKeys.forClient(clientId),
        queryFn: ({ queryKey, signal }) => {
            const id = queryKey[1] !== undefined ? `?clientId=${queryKey[1]}` : "";
            return apiGet<SuperAdminCustomValueListDto>(`${queryKey[0]}${id}`, { signal });
        },
        enabled,
    });
};

export const useSuperAdminCreateCustomValue = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: SuperAdminCustomValueDto) => apiPost<SuperAdminCustomValueDto>(SUPERADMIN_CUSTOM_VALUES_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CUSTOM_VALUES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CUSTOMVALUE_CREATED));
        },
    });
};

export const useSuperAdminUpdateCustomValue = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: { id: number; value: SuperAdminCustomValueDto }) =>
            apiPatch<SuperAdminCustomValueDto>(`${SUPERADMIN_CUSTOM_VALUES_PATH}/${data.id}`, data.value),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CUSTOM_VALUES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CUSTOMVALUE_UPDATED));
        },
    });
};

export const useSuperAdminDeleteCustomValue = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (customValueId: number) => apiDelete<BasicMessageDto>(`${SUPERADMIN_CUSTOM_VALUES_PATH}/${customValueId}`),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CUSTOM_VALUES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CUSTOMVALUE_DELETED));
        },
    });
};

export const useSuperAdminUpdateCustomValuesOrder = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: ChangeCustomValuesOrderInputDto) =>
            apiPatch<SuperAdminCustomValueListDto>(`${SUPERADMIN_CUSTOM_VALUES_PATH}/change-order/${data.measureAttributeId}`, {
                customValues: data.customValues,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries([SUPERADMIN_CUSTOM_VALUES_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_CUSTOMVALUE_UPDATED));
        },
    });
};
