import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    AsyncTaskStatus,
    SuperAdminAsyncTaskDto,
    SuperAdminMigratedCustomFieldRequestBody,
    type SuperAdminMigratedCustomFieldDto,
} from "api-shared";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications.ts";
import { apiGet, apiPost } from "../../lib/api.ts";
import { NotificationType } from "../../lib/notifications.ts";
import { FeedbackTranslationKeys } from "../../translations/notification-translations.ts";
import { SuperAdminAttributesQueryKeys } from "./attributes.ts";
import { SuperAdminCustomFieldQueryKeys } from "./custom-fields.ts";
import { SuperAdminTranslationsQueryKeys } from "./translations.ts";
import { SuperAdminTreeNodeQueryKeys } from "./tree-nodes.ts";

const SUPER_ADMIN_MIGRATED_CUSTOM_FIELD_PATH = "superadmin/migrated-custom-field";

export const useSuperAdminMigratedCustomFieldMutation = ({
    onSuccess,
}: {
    onSuccess?: (data: SuperAdminMigratedCustomFieldDto) => void;
}) => {
    return useMutation({
        mutationFn: (data: SuperAdminMigratedCustomFieldRequestBody) =>
            apiPost<SuperAdminMigratedCustomFieldDto>(`${SUPER_ADMIN_MIGRATED_CUSTOM_FIELD_PATH}`, data),
        onSuccess,
    });
};

export const useSuperAdminMigratedCustomFieldTaskStatusQuery = ({ id, onFinish }: { id: number | undefined; onFinish: () => void }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const query = useQuery({
        queryKey: [SUPER_ADMIN_MIGRATED_CUSTOM_FIELD_PATH, "status", id],
        queryFn: ({ signal }) => apiGet<SuperAdminAsyncTaskDto>(`${SUPER_ADMIN_MIGRATED_CUSTOM_FIELD_PATH}/status/${id}`, { signal }),
        enabled: id !== undefined,
        refetchInterval: 2000,
    });

    useEffect(() => {
        if (id == null) {
            return;
        }

        const status = query.data?.status;
        if (status === AsyncTaskStatus.Completed) {
            onFinish();

            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_ATTRIBUTE_MIGRATE_TO_TREE));
            queryClient.invalidateQueries([
                SuperAdminCustomFieldQueryKeys.all,
                SuperAdminAttributesQueryKeys.all,
                SuperAdminTranslationsQueryKeys.all,
                SuperAdminTreeNodeQueryKeys.all,
            ]);
        } else if (status === AsyncTaskStatus.Failed) {
            onFinish();
            const errors = query.data?.result?.errors;

            const foo = JSON.stringify(errors);
            dispatch(
                showNotificationEvent(NotificationType.ERROR, FeedbackTranslationKeys.VDLANG_ATTRIBUTE_MIGRATE_TO_TREE_FAILED, {
                    errors: foo,
                }),
            );
        }
    }, [id, onFinish, dispatch, queryClient, query.data]);

    return query;
};
