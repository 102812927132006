import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BasicMessageDto, TreeNodeListDto } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiGet, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";

const SUPER_ADMIN_TREE_NODES_PATH = "superadmin/tree-nodes";
const SUPER_ADMIN_TREE_NODES_UPLOAD_PATH = "superadmin/tree-nodes/upload";

interface UploadTreeNodesInputDto {
    attributeId: number;
    clientId: number;
    file: File;
}

export const SuperAdminTreeNodeQueryKeys = {
    all: [SUPER_ADMIN_TREE_NODES_PATH] as const,
    forClientAttribute: (clientId: number, attributeId: number) => [...SuperAdminTreeNodeQueryKeys.all, clientId, attributeId] as const,
};

export const useSuperAdminTreeNodes = (clientId: number, attributeId: number) => {
    return useQuery({
        queryKey: SuperAdminTreeNodeQueryKeys.forClientAttribute(clientId, attributeId),
        queryFn: ({ queryKey, signal }) =>
            apiGet<TreeNodeListDto>(`${SUPER_ADMIN_TREE_NODES_PATH}/${queryKey[1]}?attributeId=${queryKey[2]}`, { signal }),
    });
};

export const useSuperAdminUploadTreeNodes = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: UploadTreeNodesInputDto) => {
            const formData = new FormData();
            formData.append("file", data.file);
            formData.append("clientId", `${data.clientId}`);
            formData.append("attributeId", `${data.attributeId}`);
            return apiPost<BasicMessageDto>(SUPER_ADMIN_TREE_NODES_UPLOAD_PATH, formData);
        },
        onSuccess: (_, request) => {
            queryClient.invalidateQueries(SuperAdminTreeNodeQueryKeys.forClientAttribute(request.clientId, request.attributeId));
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_TREE_NODES_UPLOAD_SUCCESS));
        },
        onError: () => {
            dispatch(showNotificationEvent(NotificationType.ERROR, FeedbackTranslationKeys.VDLANG_FEEDBACK_TREE_NODES_UPLOAD_ERROR));
        },
        meta: { ignoreErrors: true },
    });
};
