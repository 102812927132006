import { FormControl, FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { FeatureFlags, zMaxInt } from "api-shared";
import React from "react";
import { useTranslation } from "react-i18next";
import { OnChangeValue } from "react-select";
import TimezoneSelect from "../../../components/TimezoneSelect";
import MultiIntegerInput from "../../../components/input/MultiIntegerInput";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import { translationKeys } from "../../../translations/main-translations";
import { IFlatClient } from "./ClientListTable";

interface IClientDialogProps {
    client: IFlatClient;
    updateClient: (newValue: IFlatClient, isValid: boolean) => void;
}

type ClientValueType = string | boolean | number | null;

const validateValue = (val: unknown, key?: string) => {
    // ignore all object values
    const ignoredKeys = [
        "internalContactId",
        "defaultGroupId",
        "externalUserGroupId",
        "logoHash",
        "dashboardColors",
        "defaultMeasureGroupId",
    ];
    if (key && ignoredKeys.includes(key)) {
        return val == null || (typeof val === "number" && val >= 0 && !isNaN(val));
    }
    if (key === "ideaPotentialIntervals") {
        return val === null || (Array.isArray(val) && (val.length === 0 || val.length === 5) && val.every((v) => !isNaN(v)));
    }
    if (key !== undefined && ["duns"].includes(key)) {
        return zMaxInt.safeParse(val).success;
    }
    if (key !== undefined && ["advancedUserSeats", "basicUserSeats", "lightUserSeats", "adminUserSeats"].includes(key)) {
        return zMaxInt.nullable().safeParse(val).success;
    }

    if (typeof val === "string") {
        return val.trim() !== "";
    }

    return false;
};

const validateClient = (client: IFlatClient) => {
    // do not validate properties that are objects
    // typeof null is also "object", so add allow passing null values explicitly
    // exception for ideaPotentialIntervals which is an object but can be validated
    return Object.entries(client)
        .filter(([key, value]) => typeof value !== "object" || value === null || key === "ideaPotentialIntervals")
        .every(([key, value]) => validateValue(value, key));
};

const ClientDialog = ({ client, updateClient }: IClientDialogProps) => {
    const { t } = useTranslation();

    const allFeatures = Object.values(FeatureFlags);

    const onInputChanged = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = target;
        const value: ClientValueType = target.type === "checkbox" ? target.checked : target.value;

        const updatedClient = { ...client, [name]: value };

        updateClient(updatedClient, validateClient(updatedClient));
    };

    const onTimezoneChanged = (timezone: string | null) => {
        if (timezone == null) {
            return;
        }
        const updatedClient = { ...client, timezone };
        updateClient(updatedClient, validateClient(updatedClient));
    };

    const onSeatsChanged = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target;
        const parsedValue = value.length === 0 ? null : +value;
        const updatedClient = { ...client, [target.name]: parsedValue };
        updateClient(updatedClient, validateClient(updatedClient));
    };

    const handleFeatureChange = (selected: OnChangeValue<Option, true>) => {
        const newFeatures: {
            [key: string]: number;
        } = {};

        allFeatures.forEach((feat) => {
            const isSelected = selected.find((selectedFeat) => selectedFeat.value === feat) !== undefined;
            newFeatures[feat] = isSelected ? 1 : 0;
        });

        const updatedClient = { ...client, features: newFeatures };
        updateClient(updatedClient, validateClient(updatedClient));
    };

    const handleIdeaPotentialIntervalsChange = (newValue: number[]) => {
        const sortedIntervals = [...newValue].sort((a, b) => a - b);
        const updatedClient = { ...client, ideaPotentialIntervals: sortedIntervals };
        updateClient(updatedClient, validateClient(updatedClient));
    };

    const {
        name,
        alias,
        duns,
        isCustomer,
        adminUserSeats,
        advancedUserSeats,
        basicUserSeats,
        lightUserSeats,
        fiscalYear,
        timezone,
        fiscalYearRangePast,
        fiscalYearRangeFuture,
        currencyName,
        currencyIsoCode,
        features,
        ideaPotentialIntervals,
        endOfRecurrence,
    } = client;

    const allFeatureFlagOptions = allFeatures.map((f) => ({ label: f, value: f }));
    const selectedFeatureFlagOptions = allFeatureFlagOptions.filter(({ value }) => features[value]);

    return (
        <FormGroup>
            <TextField
                required
                label="Name"
                autoFocus
                name="name"
                value={name}
                onChange={onInputChanged}
                error={!validateValue(name)}
                margin="normal"
            />
            <TextField
                required
                name="alias"
                label="Alias"
                value={alias}
                onChange={onInputChanged}
                error={!validateValue(alias)}
                margin="normal"
            />
            <TextField
                required
                name="duns"
                type="number"
                label="DUNS"
                value={duns ?? ""}
                onChange={onInputChanged}
                error={!validateValue(duns, "duns")}
                helperText={!validateValue(duns, "duns") && t(translationKeys.VDLANG_SUPERADMIN_VALUE_TOO_LARGE)}
                margin="normal"
            />
            <TextField
                name="adminUserSeats"
                type="number"
                label="Admin User Seats"
                value={adminUserSeats ?? ""}
                onChange={onSeatsChanged}
                error={!validateValue(adminUserSeats, "adminUserSeats")}
                helperText={!validateValue(adminUserSeats, "adminUserSeats") && t(translationKeys.VDLANG_SUPERADMIN_VALUE_TOO_LARGE)}
                placeholder="Unlimited"
                margin="normal"
            />
            <TextField
                name="advancedUserSeats"
                type="number"
                label="Advanced/Full User Seats"
                value={advancedUserSeats ?? ""}
                onChange={onSeatsChanged}
                error={!validateValue(advancedUserSeats, "advancedUserSeats")}
                helperText={!validateValue(advancedUserSeats, "advancedUserSeats") && t(translationKeys.VDLANG_SUPERADMIN_VALUE_TOO_LARGE)}
                placeholder="Unlimited"
                margin="normal"
            />
            <TextField
                name="basicUserSeats"
                type="number"
                label="Basic/Contributor User Seats"
                value={basicUserSeats ?? ""}
                onChange={onSeatsChanged}
                error={!validateValue(basicUserSeats, "basicUserSeats")}
                helperText={!validateValue(basicUserSeats, "basicUserSeats") && t(translationKeys.VDLANG_SUPERADMIN_VALUE_TOO_LARGE)}
                placeholder="Unlimited"
                margin="normal"
            />
            <TextField
                name="lightUserSeats"
                type="number"
                label="Light/Ideator User Seats"
                value={lightUserSeats ?? ""}
                onChange={onSeatsChanged}
                error={!validateValue(lightUserSeats, "lightUserSeats")}
                helperText={!validateValue(lightUserSeats, "lightUserSeats") && t(translationKeys.VDLANG_SUPERADMIN_VALUE_TOO_LARGE)}
                placeholder="Unlimited"
                margin="normal"
            />
            <TextField
                required
                name="currencyName"
                type="text"
                label="Currency Name"
                disabled={client?.id > 0} // allow modifications of currency only when creating a new client
                value={currencyName}
                onChange={onInputChanged}
                error={!validateValue(currencyName)}
                margin="normal"
            />
            <TextField
                required
                name="currencyIsoCode"
                type="text"
                label="Currency ISO Code"
                disabled={client?.id > 0} // allow modifications of currency only when creating a new client
                value={currencyIsoCode}
                onChange={onInputChanged}
                error={!validateValue(currencyIsoCode)}
                margin="normal"
            />
            <TextField
                required
                name="fiscalYear"
                type="number"
                label="Fiscalyear"
                disabled={client?.id > 0} // allow modifications of fiscalYear only when creating a new client
                value={fiscalYear}
                onChange={onInputChanged}
                error={!validateValue(fiscalYear)}
                margin="normal"
            />
            <TextField
                required
                name="fiscalYearRangePast"
                type="number"
                label="Fiscalyear Range Past"
                value={fiscalYearRangePast}
                onChange={onInputChanged}
                error={!validateValue(fiscalYearRangePast)}
                margin="normal"
            />
            <TextField
                required
                name="fiscalYearRangeFuture"
                type="number"
                label="Fiscalyear Range Future"
                value={fiscalYearRangeFuture}
                onChange={onInputChanged}
                error={!validateValue(fiscalYearRangeFuture)}
                margin="normal"
            />
            <MultiIntegerInput
                name="ideaPotentialIntervals"
                label="Idea Potential Intervals"
                value={ideaPotentialIntervals ?? []}
                onChange={handleIdeaPotentialIntervalsChange}
                error={!validateValue(ideaPotentialIntervals, "ideaPotentialIntervals")}
            />
            <Select
                isMulti
                onChange={handleFeatureChange}
                value={selectedFeatureFlagOptions}
                options={allFeatures.map((f) => ({ label: f, value: f }))}
                label="Features"
                menuPortalTarget={document.body}
            />
            {selectedFeatureFlagOptions.map(({ label }) => label).includes(FeatureFlags.FEATURE_RECURRING_EFFECTS) && (
                <TextField
                    name="endOfRecurrence"
                    type="date"
                    label="End of recurrence"
                    value={endOfRecurrence}
                    onChange={onInputChanged}
                    margin="normal"
                    disabled // for now this is set by a migration and can not be changed
                />
            )}
            <TimezoneSelect required label="Timezone" value={timezone} onChange={onTimezoneChanged} />
            <FormControl>
                <FormControlLabel
                    control={<Switch checked={Boolean(isCustomer)} onChange={onInputChanged} name="isCustomer" title="isCustomer" />}
                    label="Is customer"
                />
            </FormControl>
        </FormGroup>
    );
};

export default ClientDialog;
