import { FormControl, FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { isValidPassword } from "api-shared";
import React from "react";
import { CreateClientUserDto } from "../../../domain/superadmin/clients";

interface InitialUserDialogProps {
    user: CreateClientUserDto;
    updateUser: (user: CreateClientUserDto, isValid: boolean) => void;
}

const validateValue = (val: string | boolean | null | undefined) => val != null && String(val).trim() !== "";

const validatePassword = (val: unknown) => typeof val === "string" && isValidPassword(val);

const validateField = ([key, value]: [string, string | boolean | null]) => {
    if (key === "password") {
        return validatePassword(value);
    }
    return validateValue(value);
};

const getValidationStatus = (user: CreateClientUserDto) => {
    if (user.createInitialUser) {
        return Object.entries(user).every(validateField);
    }
    return true;
};

const InitialUserDialog = ({ user, updateUser }: InitialUserDialogProps) => {
    const onInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const updatedUser = { ...user, [name]: value };
        updateUser(updatedUser, getValidationStatus(updatedUser));
    };

    const { createInitialUser, realname, email, password } = user;

    return (
        <FormGroup>
            <FormControl>
                <FormControlLabel
                    control={
                        <Switch checked={createInitialUser} onChange={onInputChanged} name="createInitialUser" title="createInitialUser" />
                    }
                    label="Create initial admin user"
                />
            </FormControl>
            {createInitialUser ? (
                <>
                    <TextField
                        required
                        label="Realname"
                        name="realname"
                        value={realname}
                        onChange={onInputChanged}
                        error={!validateValue(realname)}
                        margin="normal"
                    />
                    <TextField
                        required
                        label="Email"
                        name="email"
                        value={email}
                        onChange={onInputChanged}
                        error={!validateValue(email)}
                        margin="normal"
                    />
                    <TextField
                        required
                        label="Password"
                        name="password"
                        value={password}
                        onChange={onInputChanged}
                        error={!validatePassword(password)}
                        margin="normal"
                        type="password"
                    />
                </>
            ) : null}
        </FormGroup>
    );
};

export default InitialUserDialog;
